import React, { Component } from "react";
import Pagination from "react-js-pagination";

import "./paginationJs.scss";

class PaginationJs extends Component {
  handlePageChange = (pageNumber) => {
    this.props.changeActivePage(pageNumber);
  };

  changeSize = (newVal) => {
    this.props.dataToShowHandler(newVal);
  };

  paginationSelector = (totalData) => {
    return (
      <>
        <>
          {totalData > 20 ? (
            <>
              <div
                className={`size ${
                  this.props.dataToShow === 20 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(20)}
              >
                20
              </div>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 40 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(40)}
              >
                40
              </div>
            </>
          ) : null}
        </>
        <>
          {totalData > 40 ? (
            <>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 60 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(60)}
              >
                60
              </div>
            </>
          ) : null}
        </>
        <>
          {totalData > 60 ? (
            <>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 80 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(80)}
              >
                80
              </div>
            </>
          ) : null}
        </>
        <>
          {totalData > 80 ? (
            <>
              <div className="divider">|</div>
              <div
                className={`size ${
                  this.props.dataToShow === 100 ? "selected" : ""
                }`}
                onClick={() => this.changeSize(100)}
              >
                100
              </div>
            </>
          ) : null}
        </>
      </>
    );
  };

  render() {
    return this.props.totalData > 20 ? (
      <div>
        <div className="limitWrapper">
          {/* <div className="count">Count:</div> */}
          {/* <div className={`size ${this.props.dataToShow === 20 ? 'selected' : ''}`} onClick={() => this.changeSize(20)}>20</div> 
                        <div className="divider">|</div>
                        <div className={`size ${this.props.dataToShow === 40 ? 'selected' : ''}`} onClick={() => this.changeSize(40)}>40</div> 
                        <div className="divider">|</div>
                        <div className={`size ${this.props.dataToShow === 60 ? 'selected' : ''}`} onClick={() => this.changeSize(60)}>60</div>  */}
          {this.paginationSelector(this.props.totalData)}
          {/* <div className="divider">|</div>
                        <div className={`size ${this.props.dataToShow === 80 ? 'selected' : ''}`} onClick={() => this.changeSize(80)}>80</div> 
                        <div className="divider">|</div>
                        <div className={`size ${this.props.dataToShow === 100 ? 'selected' : ''}`} onClick={() => this.changeSize(100)}>100</div> */}
        </div>
        <Pagination
          activePage={this.props.activePage}
          itemsCountPerPage={this.props.dataToShow}
          totalItemsCount={this.props.totalData}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange}
        />
      </div>
    ) : null;
  }
}

export default PaginationJs;
