//library import
import React from "react";
import moment from "moment";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
//scss
import "../Common.scss";
import { notifymeExport, addExportDate } from "../../../services/notifymeLogs";
import { notifyExportMecolumns } from "../../../fixtures/tableColumns/notifyExportMecolumns";
import ReactTable from "../../../components/SmsLogReactTable/ExportreactTable";
import { getFromLocalStorage } from "../../../lib/local-storage";
import PaginationJs from "../../../components/paginationJs/paginationJs";
import Loader from "../../../components/loader/Loader";

class ExportLogs extends React.Component {
  state = {
    logsData: [], //table data
    addTableData: true,
    columns: [...notifyExportMecolumns], //table columns
    date: {
      //start date and end date
      startdate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    },

    skip: 0, //starting point
    limit: 20, //ending point
    page: 1, //pagination page
    dataToShow: 20, //20, 40, 60 or 80 data to show
    totalData: Number, // total no of data
    loader: true, //loader state
    showExportTable: true,
    showExportPage: false,
    expoStartdate: null,
    exporEndDate: null,
    // addModalShow: false,
  };

  componentDidUpdate = (prevProps, prevState) => {
    // this.postData(this.state.date.startdate, this.state.date.endDate);

    //old params and new params are diff than get new data
    if (
      JSON.stringify(this.state.date) !== JSON.stringify(prevState.date) ||
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow
    ) {
      this.getData(
        this.state.date.startdate,
        this.state.date.endDate,
        this.state.skip,
        this.state.limit
      );
    }
    //old params and new params are same ...close loader
    else if (this.state.loader === true) this.setState({ loader: false });
  };

  //get initial data on didmount
  componentDidMount() {
    this.getData(
      this.state.date.startdate,
      this.state.date.endDate,
      this.state.skip,
      this.state.limit
    );
  }

  //for getting the export report besed on selected date.
  postData = (start_time, end_time, type) => {
    const dataToSend = {
      type: 5,
      start_time: moment(start_time).unix(),
      end_time: moment(end_time).unix(),
      storeId: getFromLocalStorage("store_id"),
    };

    //calling the post api
    addExportDate(dataToSend)
      .then((res) => {
        //getting the update listing using export api
        this.getData(
          this.state.date.startdate,
          this.state.date.endDate,
          this.state.skip,
          this.state.limit
        );
      })

      .catch((e) => {
        console.log(e, "errrrrrrrrrrrrr");
        this.setState({ loader: false });
      });
  };

  //method to fetch data
  getData = (startdate, enddate, skip, limit, city) => {
    notifymeExport(
      moment(startdate).unix(),
      moment(enddate).unix(),
      skip,
      limit
    )
      .then((data) => {

        this.setState({
          logsData: data.data.data,
          totalData: data.data.count,
          loader: false,
        });
      })
      .catch((e) => {
        console.log(e, "errrrrrrrrrrrrr");
        if (e && e.data && (e.data.status === 204 || e.data.status === 404)) {
          this.setState({
            logsData: [],
          });
        }
        this.setState({ loader: false });
      });
  };

  //set range for days
  handleRange = (startdate, enddate) => {
    let stateObject = { ...this.state };
    let date = { ...stateObject.date };
    date.startdate = startdate;
    date.endDate = enddate;
    stateObject.date = date;
    stateObject.loader = true;
    this.setState(stateObject);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    // state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  exportfun = (type, date) => {
    if (type === "from") {
      this.setState({ expoStartdate: date });
    }
    if (type === "to") {
      this.setState({ exporEndDate: date });
    }
    if (type === "submit") {
      if (this.state.expoStartdate && this.state.exporEndDate) {
        this.postData(this.state.expoStartdate, this.state.exporEndDate);
        this.setState({ expoStartdate: null, exporEndDate: null });
      }
    }
  };

  handleExportRecord = (bool) => {
    this.setState({
      exportRecord: bool,
    });
  };

  //using this method in the pagination
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };



    stateCopy.page = 1;
    stateCopy.skip = 0;
    stateCopy.limit = newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  render() {
    return (
      <React.Fragment>
        <div className="table reviewLogs">
          {
            //conditional rendering of loader
            this.state.loader && <Loader />
          }

          <div className="selectWrapper">
            <Breadcrumbs ExportButton={this.props.handleExportButton} />
          </div>

          <div className="mt-4">
            <ReactTable
              expo={this.exportfun}
              showExportTable={this.state.showExportTable}
              addTableData={this.state.addTableData}
              lable="Export"
              columns={this.state.columns}
              data={this.state.logsData}
              noDataText="No review logs"
              handleRange={this.handleRange.bind(this)}
            />
          </div>
          <div className="paginationWrapper">
            <PaginationJs
              changeActivePage={this.changeActivePage}
              activePage={this.state.page}
              totalData={this.state.totalData}
              dataToShowHandler={this.dataToShowHandler}
              dataToShow={this.state.dataToShow}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ExportLogs;
