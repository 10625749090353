//library import
import React from "react";
import moment from "moment";
import PaginationJs from '../../../components/paginationJs/paginationJs'
import ReactSelect from '../../../components/reactSelect/reactSelect'
import TextField from '../../../components/textField/textField'

//custom import
import ReactTable from "../../../components/reactTable/reactTable";
import Lable from "../../../components/dashbordLable/dashbordLable";
import { fetchProductClickLogs, fetchStoreCategoryName, fetchProductCategoryName, fectchCityName } from "../../../services/productClickLogs";
import { productClickLogsColumns } from "../../../fixtures/tableColumns/productClickLogs";
import Loader from '../../../components/loader/Loader'


//scss
import "../Common.scss";

class CategoryLogs extends React.Component {

  state = {
    logsData: [],                                   //table data
    columns: [...productClickLogsColumns],           //table columns
    date: {                                         //start date and end date
      startdate: moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD HH:mm:ss"),

    },

    cityData: [{ value: "", label: "All Cities" }],
    selectedCity: { value: "", label: "All Cities" },

    storeCategoryData: [{ value: "", label: "All Store Category" }],
    selectedStoreCategory : { value: "", label: "All Store Category" },

    productCategoryData: [{ value: "", label: "All Product Category" }],
    selectedProductCategory: { value: "", label: "All Product Category" },

    categoryData: [{ value: "", label: "All Categories" }],
    selectedCategory: { value: "", label: "All Categories" },

    subCategoryData: [{ value: "", label: "All Sub Categories" }],
    selectedSubCategory: { value: "", label: "All Sub Categories" },

    subSubCategoryData: [{ value: "", label: "All Sub Sub Categories" }],
    selectedSubSubCategory: { value: "", label: "All Sub Sub Categories" },

    skip: 0,                                        //starting point
    limit: 20,                                      //ending point 
    page: 1,                                        //pagination page
    dataToShow: 20,                                 //20 or 100 data to show 
    totalData: Number,                              // total no of data
    loader: true,                                   //loader state
    filteredObject: "",
  };



  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };




    // let newPage;

    // if (newVal === 100) {
    //   newPage = Math.trunc((stateCopy.page % 2 == 0) ? stateCopy.page / 2 : stateCopy.page / 2 + 1);
    // }
    // else {
    //   newPage = stateCopy.page * 2 - 1;
    // }

    // stateCopy.page = newPage;
    // stateCopy.skip = stateCopy.page * newVal - newVal;

    stateCopy.page = 1;
    stateCopy.skip = 0;
    stateCopy.limit = stateCopy.page * newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy)
  }

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state)
  }

  //get new data 
  componentDidUpdate = (prevProps, prevState) => {


    //old params and new params are diff than get new data
    if (JSON.stringify(this.state.date) !== JSON.stringify(prevState.date)
      || (this.state.page !== prevState.page)
      || (this.state.dataToShow !== prevState.dataToShow)
      || (JSON.stringify(this.state.selectedStoreCategory) !== JSON.stringify(prevState.selectedStoreCategory))
      || (JSON.stringify(this.state.selectedProductCategory) !== JSON.stringify(prevState.selectedProductCategory))
      || (JSON.stringify(this.state.selectedCity) !== JSON.stringify(prevState.selectedCity))
      // || (JSON.stringify(this.state.selectedCategory) !== JSON.stringify(prevState.selectedCategory))
      // || (JSON.stringify(this.state.selectedSubCategory) !== JSON.stringify(prevState.selectedSubCategory))
      // || (JSON.stringify(this.state.selectedSubSubCategory) !== JSON.stringify(prevState.selectedSubSubCategory))


    ) {


      this.getData(
        this.state.date.startdate,
        this.state.date.endDate,
        this.state.skip,
        this.state.limit,
        this.state.selectedCity.value,
        this.state.selectedStoreCategory.value,
        this.state.selectedProductCategory.value,

        // this.state.selectedCategory.value,
        // this.state.selectedSubCategory.value,
        // this.state.selectedSubSubCategory.value
      );
    }
    //old params and new params are same ...close loader
    else if (this.state.loader === true)
      this.setState({ loader: false })

    if (JSON.stringify(this.state.selectedStoreCategory) !== JSON.stringify(prevState.selectedStoreCategory)) {
      this.getProductCategoryData(this.state.selectedStoreCategory.value)
    }
    else if (this.state.loader === true)
    this.setState({ loader: false })
  }

  //get initial data on didmount
  componentDidMount() {
    this.getData(
      this.state.date.startdate,
      this.state.date.endDate,
      this.state.skip,
      this.state.limit,
      this.state.selectedCity.value,

      this.state.selectedCategory.value,
      this.state.selectedSubCategory.value,
      this.state.selectedSubSubCategory.value
    );
    this.getStoreCategoryData();
    this.getCityData()

  }


  //method to fetch data
  getData = (startdate, enddate, skip, limit, city, storeCategoryId, productCategoryId, category, subCategory, subSubCategory) => {
    fetchProductClickLogs(
      moment(startdate).format("YYYY-MM-DD HH:mm:ss"),
      moment(enddate).format("YYYY-MM-DD HH:mm:ss"),
      skip,
      limit,
      city,
      storeCategoryId,
      productCategoryId
      // category,
      // subCategory,
      // subSubCategory
    )
      .then(data => {


        // let cityData = [{ value: "", label: "All Cities" }];
        // data.data.cityData.map((item) => {
        //   cityData.push({ value: item, label: item })
        // })

        // let categoryData = [{ value: "", label: "All Categories" }];
        // data.data.categoryData.map((item) => {
        //   categoryData.push({ value: item, label: item })
        // })

        // let subCategoryData = [{ value: "", label: "All Sub Categories" }];
        // data.data.subCategoryData.map((item) => {
        //   subCategoryData.push({ value: item, label: item })
        // })

        // let subSubCategoryData = [{ value: "", label: "All Sub Sub Categories" }];
        // data.data.subSubCategoryData.map((item) => {
        //   subSubCategoryData.push({ value: item, label: item })
        // })


        this.setState(
          {
            logsData: data.data.productData,
            totalData: data.data.total_count,
            loader: false,

            // cityData:
            //   (this.state.filteredObject === "") ? cityData : this.state.cityData,
            // categoryData:
            //   (this.state.filteredObject === ""
            //     || this.state.filteredObject === "city")
            //     ? categoryData : this.state.categoryData,
            // subCategoryData:
            //   (this.state.filteredObject === ""
            //     || this.state.filteredObject === "city"
            //     || this.state.filteredObject === "category")
            //     ? subCategoryData : this.state.subCategoryData,
            // subSubCategoryData: (
            //   this.state.filteredObject === ""
            //   || this.state.filteredObject === "city"
            //   || this.state.filteredObject === "category"
            //   || this.state.filteredObject === "subCategory")
            //   ? subSubCategoryData : this.state.subSubCategoryData
          }
        );
      })
      .catch(e => {
        console.log(e, "errrrrrrrrrrrrr", e.response, "respppppppp")
        this.setState({ loader: false })
      });
    // .catch(e => {
;
    //   // if (e.response.status == 404) {
    //   //   this.setState({
    //   //     logsData: e.response.data.data
    //   //   });
    //   // }
    // });
  };

  getStoreCategoryData() {
    fetchStoreCategoryName()
    .then(data => { 
      let storeData = [{ value: "", label: "All Store Category" }];

      data.data.data.map((item) => {
        storeData.push({ value: item.id, label: item.storeCategoryName.en })
      })
      this.setState(
        {
          storeCategoryData: storeData,
          loader: false,
          selectedProductCategory: { value: "", label: "All Product Category" }
        }
      );
    })
    .catch(e => {
      console.log(e, "errrrrrrrrrrrrr")
      this.setState({ loader: false })
    });
  }

  getProductCategoryData(storeCategoryId) {
    fetchProductCategoryName(storeCategoryId)
    .then(data => {
      // let response = JSON.parse(data.data)
      let productData = [{ value: "", label: "All Product Category" }];
      
      data.data.data.map((item) => {
        productData.push({ value: item.id, label: item.categoryName.en })
      })
      this.setState(
        {
          productCategoryData: productData,
          loader: false
        }
      );
    })
    .catch(e => {
      console.log(e, "errrrrrrrrrrrrr")
      this.setState({ loader: false })
    });
  }

  getCityData() {
    fectchCityName()
      .then(data => {
        let cities = [{ value: "", label: "All Cities" }];
        
        data.data.data.map((item) => {
          cities.push({ value: item.cityName, label: item.cityName })
        })
        this.setState(
          {
            cityData: cities,
            loader: false
          }
        );
      })
      .catch(e => {
        console.log(e, "errrrrrrrrrrrrr")
        this.setState({ loader: false })
      });
  }

  // onPageChange = arges => {
  // };

  //set range for days
  handleRange = (startdate, enddate) => {
    let stateObject = { ...this.state };
    let date = { ...stateObject.date };
    date.startdate = startdate;
    date.endDate = enddate;
    stateObject.date = date
    stateObject.loader = true
    this.setState(stateObject);
    // this.getSubCategoty(startdate, enddate);

  };

  filterHandler = (category, value) => {
    let state = { ...this.state };

    state.page = 1;
    state.skip = state.page * state.dataToShow - state.dataToShow;
    state.limit = state.page * state.dataToShow;

    state.loader = true;
    state.filteredObject = category;

    switch (category) {
      case "city": state.selectedCity = value;
        state.selectedCategory = [{ value: "", label: "All Categories" }];
        state.selectedSubCategory = [{ value: "", label: "All Sub Categories" }];
        state.selectedSubSubCategory = [{ value: "", label: "All Sub Sub Categories" }];
        break;

      case "category":
        state.selectedCategory = value;
        state.selectedSubCategory = [{ value: "", label: "All Sub Categories" }];
        state.selectedSubSubCategory = [{ value: "", label: "All Sub Sub Categories" }];
        break;

      case "subCategory":
        state.selectedSubCategory = value;
        state.selectedSubSubCategory = [{ value: "", label: "All Sub Sub Categories" }];
        break;

      case "subSubCategory": state.selectedSubSubCategory = value;
        break;
    }
    this.setState(state)
  }

  changeStoreCategoryHandler = (category) => {
    let state = { ...this.state };

    state.page = 1;
    state.skip = state.page * state.dataToShow - state.dataToShow;
    state.limit = state.page * state.dataToShow;

    state.loader = true;
    state.selectedStoreCategory = category;
    this.setState(state)
  }

  changeProductCategoryHandler = (category) => {
    let state = { ...this.state };

    state.page = 1;
    state.skip = state.page * state.dataToShow - state.dataToShow;
    state.limit = state.page * state.dataToShow;

    state.loader = true;
    state.selectedProductCategory = category;
    this.setState(state)
  }

  changeCityHandler = (city) => {
    let state = { ...this.state };

    state.page = 1;
    state.skip = state.page * state.dataToShow - state.dataToShow;
    state.limit = state.page * state.dataToShow;

    state.loader = true;
    state.selectedCity = city;

    this.setState(state)
  }

  render() {

    return (
      <div className="table productClickLogs">
        {
          //conditional rendering of loader
          this.state.loader && <Loader />
        }
          {/* filtering options wrapper */}
          <div className="selectWrapper">
            <div>
              <ReactSelect city={this.state.storeCategoryData}
                change={this.changeStoreCategoryHandler}
                selected={this.state.selectedStoreCategory} />
            </div>
            <div> 
              <ReactSelect city={this.state.productCategoryData}
                change={this.changeProductCategoryHandler}
                selected={this.state.selectedProductCategory} />
            </div>
            <div>
              <ReactSelect city={this.state.cityData}
                change={this.changeCityHandler}
                selected={this.state.selectedCity} />
            </div>
            {/* <div>
              <ReactSelect city={this.state.categoryData}
                change={this.filterHandler.bind(this, "category")}
                selected={this.state.selectedCategory} />
            </div> */}
            {/* <div>
              <ReactSelect city={this.state.subCategoryData}
                change={this.filterHandler.bind(this, "subCategory")}
                selected={this.state.selectedSubCategory} />
            </div>
            <div>
              <ReactSelect city={this.state.subSubCategoryData}
                change={this.filterHandler.bind(this, "subSubCategory")}
                selected={this.state.selectedSubSubCategory} />
            </div> */}
            {/* <div>
              <TextField label="Name/Category/SubCategory" />
            </div> */}
          </div>

          {/* table wrapper */}
          <div className="mt-4">
            <ReactTable
              // onPageChange={this.onPageChange.bind(this)}
              lable="Product Click Logs"
              columns={this.state.columns}
              data={this.state.logsData}
              noDataText="No review logs"
              handleRange={this.handleRange.bind(this)}

            />
          </div>

        {/* paginationWrapper */}
        <div className="paginationWrapper">
          <PaginationJs
            changeActivePage={this.changeActivePage}
            activePage={this.state.page}
            totalData={this.state.totalData}
            dataToShowHandler={this.dataToShowHandler}
            dataToShow={this.state.dataToShow}
          />
        </div>
      </div>
    );
  }
}

export default CategoryLogs;
