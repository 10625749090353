import React from 'react';

import Select from 'react-select';


const colourOptions = [
    { value: 'ocean', label: 'Ocean', color: '#00B8D9' },
    { value: 'blue', label: 'Blue', color: '#0052CC' },
    { value: 'purple', label: 'Purple', color: '#5243AA' },
    { value: 'red', label: 'Red', color: '#FF5630' },
    { value: 'orange', label: 'Orange', color: '#FF8B00' },
    { value: 'yellow', label: 'Yellow', color: '#FFC400' },
    { value: 'green', label: 'Green', color: '#36B37E' },
    { value: 'forest', label: 'Forest', color: '#00875A' },
    { value: 'slate', label: 'Slate', color: '#253858' },
    { value: 'silver', label: 'Silver', color: '#666666' },
];

const flavourOptions = [
    { value: 'vanilla', label: 'Vanilla', rating: 'safe' },
    { value: 'chocolate', label: 'Chocolate', rating: 'good' },
    { value: 'strawberry', label: 'Strawberry', rating: 'wild' },
    { value: 'salted-caramel', label: 'Salted Caramel', rating: 'crazy' },
];

const groupedOptions = [
    {
        label: 'Colours',
        options: colourOptions,
    },
    {
        label: 'Flavours',
        options: flavourOptions,
    },
];
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: "0.8rem",
        backgroundColor: state.isSelected ? '#3a3abf' : provided.isSelected,
        '&:hover': {
            backgroundColor: state.isSelected ? "#3a3abf" : "rgba(58, 58, 191, 0.1)"
        }
    }),
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? "2px solid #3a3abf" : "1px solid #bbb",
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
            border: state.isFocused ? "2px solid #3a3abf" : "1px solid black"
        }
    }),
    menu: (provided, state) => ({
        ...provided,
        width: '100%',
        // borderBottom: '1px dotted pink',
        color: state.selectProps.menuColor,
        zIndex: 2
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#555',
        fontSize: '0.8rem',
        fontWeight: '400'
    }),
}

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',

};

const formatGroupLabel = data => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);

export default () => (
    <Select
        styles={customStyles}
        defaultValue={colourOptions[1]}
        options={groupedOptions}
        formatGroupLabel={formatGroupLabel}
    />
);