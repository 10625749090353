//library import
import React from "react";
import moment from "moment";
import PaginationJs from "../../../components/paginationJs/paginationJs";

//custom import
import ReactTable from "../../../components/SmsLogReactTable/ExportreactTable";
// import Lable from "../../../components/dashbordLable/dashbordLable";
import { fetchNotifymeLogs } from "../../../services/notifymeLogs";
import { notifyMecolumns } from "../../../fixtures/tableColumns/notifymeLogs";
import Loader from "../../../components/loader/Loader";
import Export from "./NotifymeExport.jsx";

//scss
import "../Common.scss";

class CategoryLogs extends React.Component {
  state = {
    logsData: [], //table data
    columns: [...notifyMecolumns], //table columns
    date: {
      //start date and end date
      startdate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    },

    skip: 0, //starting point
    limit: 20, //ending point
    page: 1, //pagination page
    dataToShow: 20, //20, 40, 60 or 80 data to show
    totalData: Number, // total no of data
    loader: true, //loader state
    export: true,
    showExportPage: false,
  };

  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };



    stateCopy.page = 1;
    stateCopy.skip = 0;
    stateCopy.limit = newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy);
  };

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    // state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state);
  };

  //get new data
  componentDidUpdate = (prevProps, prevState) => {


    //old params and new params are diff than get new data
    if (
      JSON.stringify(this.state.date) !== JSON.stringify(prevState.date) ||
      this.state.page !== prevState.page ||
      this.state.dataToShow !== prevState.dataToShow
    ) {
      this.getData(
        this.state.date.startdate,
        this.state.date.endDate,
        this.state.skip,
        this.state.limit
      );
    }

    //old params and new params are same ...close loader
    else if (this.state.loader === true) this.setState({ loader: false });
  };

  //get initial data on didmount
  componentDidMount() {
    this.getData(
      this.state.date.startdate,
      this.state.date.endDate,
      this.state.skip,
      this.state.limit
      // this.state.selectedCity.value
    );
  }

  //method to fetch data for notifyme logs
  getData = (startdate, enddate, skip, limit, city) => {
    fetchNotifymeLogs(
      moment(startdate).unix(),
      moment(enddate).unix(),
      skip,
      limit
      //  city
    )
      .then((data) => {

        this.setState({
          logsData: data.data.data.table,
          totalData: data.data.count,
          loader: false,
        });
      })
      .catch((e) => {
        console.log(e, "errrrrrrrrrrrrr");
        this.setState({ loader: false });
      });
  };

  //set range for days
  handleRange = (startdate, enddate) => {
    let stateObject = { ...this.state };
    let date = { ...stateObject.date };
    date.startdate = startdate;
    date.endDate = enddate;
    stateObject.date = date;
    stateObject.loader = true;
    this.setState(stateObject);
  };

  handleExportButton = (bool) => {
    this.setState({
      showExportPage: bool,
    });
  };

  render() {
    if (this.state.showExportPage) {
      return (
        <Export
          exportPae={this.state.showExportPage}
          handleExportButton={this.handleExportButton}
        />
      );
    } else {
      return (
        <div className="table reviewLogs">
          {
            //conditional rendering of loader
            this.state.loader && <Loader />
          }

          {/* table wrapper */}
          <div className="mt-4">
            <ReactTable
              export={this.state.export}
              lable="NotifyMe Logs"
              columns={this.state.columns}
              data={this.state.logsData}
              noDataText="No review logs"
              handleRange={this.handleRange.bind(this)}
              handleExportButton={this.handleExportButton}
            />
          </div>

          {/* paginationWrapper */}
          <div className="paginationWrapper">
            <PaginationJs
              changeActivePage={this.changeActivePage}
              activePage={this.state.page}
              totalData={this.state.totalData}
              dataToShowHandler={this.dataToShowHandler}
              dataToShow={this.state.dataToShow}
            />
          </div>
        </div>
      );
    }
  }
}

export default CategoryLogs;
