import React, { Component } from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


import "./checkBox.scss";
class CheckBoxW extends Component {

  render() {

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              style={{ width: 30, height: 30, marginLeft: 5 }}
              checked={this.props.checked}
              onChange={this.props.onChange}
              value="checked"
              color="primary"
            />
          }
          label={this.props.label}
        />
      </div>
    )


  }
}
export default CheckBoxW;
