/* eslint-disable no-unused-vars */
import React from "react";

const Image = props => {
  return (
    <img {...props} />
  );
};

export default Image;
