import { replaceBlankWithNA } from "../../lib/helper";
import moment from "moment";
import React from "react";

export const notifyMecolumns = [
  {
    Header: "User Name",
    accessor: (date) => replaceBlankWithNA(date["user name"]),
    id: "user_name",
    minWidth: 80,
    // Cell: (props) => {
    //   if (!props.value) {
    //     const no_data = "N/A";
    //     return <span>{no_data}</span>;
    //   }
    // },
  },
  {
    Header: "Email Id",
    accessor: (date) => replaceBlankWithNA(date.email),
    id: "email",
    minWidth: 80,
  },
  {
    Header: "Mobile",
    accessor: (data) => replaceBlankWithNA(data.mobile),
    id: "mobile",
    minWidth: 70,
  },
  {
    Header: "Date",
    accessor: (data) => replaceBlankWithNA(data.date),
    id: "date",
    minWidth: 75,
  },
  {
    Header: "Product",
    accessor: (date) => replaceBlankWithNA(date["product name"]),
    id: "product_name",
    minWidth: 80,
  },

  {
    Header: "Address-1",
    accessor: (date) => replaceBlankWithNA(date.address1),
    id: "address1",
    minWidth: 70,
  },
  {
    Header: "Address-2",
    accessor: (date) => replaceBlankWithNA(date.address2),
    id: "address2",
    minWidth: 70,
  },
  {
    Header: "Address-3",
    accessor: (date) => replaceBlankWithNA(date.address3),
    id: "address3",
    minWidth: 70,
  },
  {
    Header: "Address-4",
    accessor: (date) => replaceBlankWithNA(date.address4),
    id: "address4",
    minWidth: 70,
  },
  {
    Header: "Address-5",
    accessor: (date) => replaceBlankWithNA(date.address5),
    id: "address5",
    minWidth: 70,
    // Cell: (props) => {
    //   if (!props.value) {
    //     const no_data = "N/A";
    //     return <span>{no_data}</span>;
    //   }
    // },
  },
  {
    Header: "Address-6",
    accessor: (date) => replaceBlankWithNA(date.address6),
    id: "address6",
    minWidth: 70,
    // Cell: (props) => {
    //   const custom_date = "custom_date";
    //   return <span>{custom_date}</span>;
    // },
  },
  {
    Header: "Address-7",
    accessor: (data) => replaceBlankWithNA(data.address7),
    id: "address7",
    minWidth: 50,
  },
];

// columns : {[
//   {
//       Header:"Signup Date",
//       accessor:"signup_date",
//       //this is the func your looking for, it can retuen custom tableCell
// Cell: (props) => {
//props.value will contain your date
//you can convert your date here
// const custom_date = "custom_date" + props.value;
// return <span>{custom_date}</span>;
// };
//   }
// ]}
