import { replaceBlankWithDash } from "../../lib/helper";
import moment from "moment";

export const emailLogsColumns = [
  // {
  //   Header: 'Index',
  //   accessor: data => replaceBlankWithDash(data.index),
  //   id: "index",
  //   minWidth: 50
  // },
  {
    Header: "Id",
    accessor: data => replaceBlankWithDash(data._id),
    id: "_id",
    minWidth: 70
  },
  {
    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.createDate),
    id: "createDate",
    minWidth: 75,
    Cell : ({ value }) => { 
      return (typeof value === "number" ?
                  moment(new Date(value*1000).toISOString()).format("DD MMM YYYY HH:mm:ss A")
                  :  value)
      },
  },
  {
    Header: "User Name",
    accessor: date => replaceBlankWithDash(date.userName),
    id: "userName",
    minWidth: 70
  },
  {
    Header: "Receiver Email Id",
    accessor: date => replaceBlankWithDash(date.emailId),
    id: "emailId",
    minWidth: 80
  },
  {

    Header: "Subject",
    accessor: data => replaceBlankWithDash(data.subject),
    id: "subject",
    minWidth: 40

  },
  {
    Header: "Source API",
    accessor: data => replaceBlankWithDash(data.emailAPI),
    id: "emailAPI",
    minWidth: 65
  },
  {
    Header: "Trigger",
    accessor: data => replaceBlankWithDash(data.trigger),
    id: "trigger",
  },

  {
    Header: "Status",
    accessor: data => replaceBlankWithDash(data.status),
    id: "status",
    minWidth: 50

  },

  {
    Header: "Delivered On",
    accessor: data => replaceBlankWithDash(data.deliveredOn),
    id: "deliveredOn",
    Cell : ({ value }) => { 
      return (typeof value === "number" ?
                  moment(new Date(value*1000).toISOString()).format("DD MMM YYYY HH:mm:ss A")
                  :  value)
      },
  },

  
 
  
  // {
  //   Header: "Request Id",
  //   accessor: data => replaceBlankWithDash(data.requestId),
  //   id: "requestId"
  // },
  // {
  //   Header: "Message Id",
  //   accessor: data => replaceBlankWithDash(data.messageId),
  //   id: "messageId"
  // },

];
