import { get, post } from "../lib/request";
import moment from "moment";

//notifyme Logs export data
export const fetchNotifymeLogs = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50
) => {
  let URL = `/notify/logs?start_time=${startDate}&end_time=${endDate}&skip=${skip}&limit=${limit}`;
  return get(URL);
};

//post api for getting the export data
export const addExportDate = (body) => {
  return post(`/export`, { body });
};

// export api for gtting the export data lists
export const notifymeExport = (
  startDate = moment(),
  endDate = moment(),
  skip = 0,
  limit = 50
) => {
  let URL = `/export?type=5&start_time=${startDate}&end_time=${endDate}&skip=${skip}&limit=${limit}&storeId=0`;
  return get(URL);
};
