import React, { Component } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import DatePicker from "../datepicker/datepicker";

class AddDateModal extends Component {
  render() {
    return (
      // <Modal {...this.props} size="lg" aria-labelledby="" centered>
      <Modal
        {...this.props}
        style={{ color: "#6c757d" }} //background: "#f8f9fa"
      >
        <Modal.Header closeButton>
          <Modal.Title id="" style={{ fontSize: "large" }}>
            Please select date range
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Record From</label>
            <div className="col-sm-8">
              {/* <input type="text" className="form-control" /> */}
              <DatePicker expo={this.props.expo} type={"from"} />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label">Record To</label>
            <div className="col-sm-8">
              <DatePicker expo={this.props.expo} type={"to"} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="default"
            style={{
              color: "#333",
              backgroundColor: "#fff",
              borderColor: "#ccc",
            }}
            onClick={this.props.onHide}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              this.props.expo("submit");
              this.props.onHide();
            }}
          >
            Export
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddDateModal;
