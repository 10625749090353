import { get } from "../lib/request";
import moment from "moment";
export const fetchProductClickLogs =
  (startDate = moment(), endDate = moment(), skip = 0, limit = 50, city = "", storeCategoryId  = "", productCategoryId= "", fname = "", sname = "", tname = "") => {
    return get(
      `/product/clicks?skip=${skip}&limit=${limit}&cityName=${city}&storeCategoryId=${storeCategoryId}&productCategoryId=${productCategoryId}&fname=${fname}&sname=${sname}&tname=${tname}`
      , { startdate: startDate, enddate: endDate }
    );
  };

  export const fetchStoreCategoryName = () => {
    return get(`/storecategories/all`)
  }
  
  export const fetchProductCategoryName = (storeCategoryId) => {
    return get(`/productcategories/all?storeCategoryId=${storeCategoryId}`)
  }
  
  export const fectchCityName = () => {
    return get(`/cities/all`)
  }