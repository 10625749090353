import { replaceBlankWithDash } from "../../lib/helper";
import Button from "@material-ui/core/Button";
import React from "react";
import moment from "moment";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export const notifyExportMecolumns = [
  {
    Header: "RECORDS FROM",
    accessor: (date) => {
      return moment.unix(date.start_ts).format("MMM-DD-YYYY");
    },
    id: "start_ts",
    minWidth: 70,
  },
  {
    Header: "RECORDS TO",
    accessor: (date) => {
      return moment.unix(date.end_ts).format("MMM-DD-YYYY");
    },
    id: "end_ts",
    minWidth: 80,
  },

  {
    Header: "EXPORTED ON",
    accessor: (date) => {
      return moment.unix(date.create_ts).format("MMM-DD-YYYY");
    },
    id: "create_ts",
    minWidth: 80,
  },

  {
    Header: "Action",
    accessor: (date) => replaceBlankWithDash(date.url),
    id: "url",
    minWidth: 70,
    Cell: ({ column, cell, row }) => (
      <Button
        style={{ padding: "0px", minWidth: "0px" }}
        href={row.url}
        target="_blank"
        download
      >
        <CloudDownloadIcon></CloudDownloadIcon>
      </Button>
    ),
  },
];
