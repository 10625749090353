// Dependency Imports
import React from "react";
import PropTypes from "prop-types";

// Custom Imports
import "../../static/scss/App.scss";
import Header from "../../components/header/header";
import SidePanel from "../../components/sidePanel/sidePanel";
//icons
import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Message";
import Product from "@material-ui/icons/ShoppingBasketOutlined";
import CategoryIcon from "@material-ui/icons/ViewCarousel";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import SearchIcon from "@material-ui/icons/Search";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import FavoriteIcon from "@material-ui/icons/Favorite";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
//helper function
import { goToLogin } from "../../lib/helper";
import { Route, withRouter, BrowserRouter as Router } from "react-router-dom";

//nested routes
import ProductSearchedLogs from "./ProductSearchedLogs/ProductSearchedLogs";
import CategoryLogs from "./CategoryLogs/CategoryLogs";
import ProductClickLogs from "./ProductClickLogs/ProductClickLogs";
import ReviewLogs from "./ReviewLogs/ReviewLogs";
import RatingLogs from "./RatingLogs/RatingLogs";
import FavouriteProductLogs from "./FavouriteProductLogs/FavouriteProductLogs";
import LoginLogs from "./LoginLogs/LoginLogs";
import EmailLogs from "./EmailLogs/EmailLogs";
import SmsLogs from "./SmsLogs/SmsLogs";
import NotifyMeLogs from "./NotifyMeLogs/NotifyMeLogs";

class LandingIndex extends React.Component {
  state = {
    cartLogs: " ",
    ProductRecentView: " ",
    FavProduct: " ",
    sidePanel: [
      {
        name: "Login Logs",
        url: `${this.props.match.url}/login-logs`,
        icon: VpnKeyIcon,
        component: LoginLogs,
      },
      {
        name: "Email Logs",
        url: `${this.props.match.url}/email-logs`,
        icon: EmailIcon,
        component: EmailLogs,
      },
      {
        name: "SMS Logs",
        url: `${this.props.match.url}/sms-logs`,
        icon: SmsIcon,
        component: SmsLogs,
      },
      {
        name: "Product Searched Logs",
        url: `${this.props.match.url}/product-searched-logs`,
        icon: SearchIcon,
        component: ProductSearchedLogs,
      },
      {
        name: "Product Review logs",
        url: `${this.props.match.url}/review-logs`,
        icon: LoyaltyIcon,
        component: ReviewLogs,
      },
      {
        name: "Product Rating logs",
        url: `${this.props.match.url}/rating-logs`,
        icon: StarHalfIcon,
        component: RatingLogs,
      },
      {
        name: "Category Logs",
        url: `${this.props.match.url}/category-logs`,
        icon: CategoryIcon,
        component: CategoryLogs,
      },
      {
        name: "Product Click Logs",
        url: `${this.props.match.url}/product-click-logs`,
        icon: Product,
        component: ProductClickLogs,
      },
      {
        name: "Favoutrite Product Logs",
        url: `${this.props.match.url}/favourite-product-logs`,
        icon: FavoriteIcon,
        component: FavouriteProductLogs,
      },

      {
        name: "NotifyMe Logs",
        url: `${this.props.match.url}/notify-me-logs`,
        icon: NotificationsActiveIcon,
        component: NotifyMeLogs,
      },
    ],
  };

  // static getDerivedStateFromProps(props, prevState) {
  //   goToLogin(props.history);
  // }

  render() {

    if (this.props.location.pathname == "/dashbord") {
      this.props.history.push("/dashbord/login-logs");
    }
    return (
      <div className="dash-board">
        <div className="dashbord-body">
          <SidePanel
            data={this.state.sidePanel}
            url={`${this.props.match.url}/login-logs`}
          ></SidePanel>

          <div className="">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  exact
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
        {/* <CartLogs cartLogs={this.state.cartLogs} />
        <ProductView ProductRecentView={this.state.ProductRecentView} />
        <FavProduct FavProduct={this.state.FavProduct} /> */}
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

export default withRouter(LandingIndex);
