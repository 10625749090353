//library import
import React from "react";
import moment from "moment";
import PaginationJs from '../../../components/paginationJs/paginationJs'
import ReactSelect from '../../../components/reactSelect/reactSelect'
import TextField from '../../../components/textField/textField'

//custom import
import ReactTable from "../../../components/reactTable/reactTable";
import Lable from "../../../components/dashbordLable/dashbordLable";
import { fetchLoginLogs } from "../../../services/loginLogs";
import { loginLogsColumns } from "../../../fixtures/tableColumns/loginLogs";
import Loader from '../../../components/loader/Loader'


//scss
import "../Common.scss";

class CategoryLogs extends React.Component {



  state = {
    logsData: [],                                   //table data
    columns: [...loginLogsColumns],           //table columns
    date: {                                         //start date and end date
      startdate: moment()
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment().format("YYYY-MM-DD HH:mm:ss"),

    },
    // cityData: [{ value: "", label: "All Cities" }],
    // selectedCity: { value: "", label: "All Cities" },
    skip: 0,                                        //starting point
    limit: 20,                                      //ending point 
    page: 1,                                        //pagination page
    dataToShow: 20,                                 //20, 40, 60 or 80 data to show 
    totalData: Number,                              // total no of data
    loader: true                                   //loader state
  };



  //set data to show and respectively set SKIP and LIMIT
  dataToShowHandler = (newVal) => {
    let stateCopy = { ...this.state };




    // let newPage;

    // if (newVal === 100) {
    //   newPage = Math.trunc((stateCopy.page % 2 == 0) ? stateCopy.page / 2 : stateCopy.page / 2 + 1);
    // }
    // else {
    //   newPage = stateCopy.page * 2 - 1;
    // }

    // stateCopy.page = newPage;
    // stateCopy.skip = stateCopy.page * newVal - newVal;
    // stateCopy.limit = stateCopy.page * newVal;
    
    stateCopy.page = 1;
    stateCopy.skip = 0;
    stateCopy.limit = newVal;
    stateCopy.dataToShow = newVal;
    stateCopy.loader = true;
    this.setState(stateCopy)
  }

  // change pagination page  respectively set SKIP and LIMIT
  changeActivePage = (page) => {
    let state = { ...this.state };

    state.skip = page * state.dataToShow - state.dataToShow;
    // state.limit = page * state.dataToShow;

    state.page = page;
    state.loader = true;
    this.setState(state)
  }

  //get new data 
  componentDidUpdate = (prevProps, prevState) => {


    //old params and new params are diff than get new data
    if (JSON.stringify(this.state.date) !== JSON.stringify(prevState.date)
      || (this.state.page !== prevState.page)
      || (this.state.dataToShow !== prevState.dataToShow)
      //  || (JSON.stringify(this.state.selectedCity) !== JSON.stringify(prevState.selectedCity))
    ) {


      this.getData(
        this.state.date.startdate,
        this.state.date.endDate,
        this.state.skip,
        this.state.limit,
        //  this.state.selectedCity.value
      );
    }

    //old params and new params are same ...close loader
    else if (this.state.loader === true)
      this.setState({ loader: false })
  }

  //get initial data on didmount
  componentDidMount() {
    this.getData(
      this.state.date.startdate,
      this.state.date.endDate,
      this.state.skip,
      this.state.limit,
      // this.state.selectedCity.value
    );
  }


  //method to fetch data
  getData = (startdate, enddate, skip, limit, city) => {
    fetchLoginLogs(moment(startdate).format("YYYY-MM-DD HH:mm:ss"),
      moment(enddate).format("YYYY-MM-DD HH:mm:ss"),
      skip,
      limit,
      //  city
    )
      .then(data => {
        // let cities = [{ value: "", label: "All Cities" }];

        // data.data.cityData.map((item) => {
        //   cities.push({ value: item, label: item })
        // })
        this.setState(
          {
            logsData: data.data.categoryData,
            totalData: data.data.total_count,
            // cityData: cities,
            loader: false
          }
        );
      })
      .catch(e => {
        console.log(e, "errrrrrrrrrrrrr")
        this.setState({ loader: false })
      });
    // .catch(e => {
;
    //   // if (e.response.status == 404) {
    //   //   this.setState({
    //   //     logsData: e.response.data.data
    //   //   });
    //   // }
    // });
  };

  // onPageChange = arges => {
  // };

  //set range for days
  handleRange = (startdate, enddate) => {
    let stateObject = { ...this.state };
    let date = { ...stateObject.date };
    date.startdate = startdate;
    date.endDate = enddate;
    stateObject.date = date
    stateObject.loader = true
    this.setState(stateObject);
    // this.getSubCategoty(startdate, enddate);

  };

  // changeCityHandler = (city) => {
  //   let state = { ...this.state };

  //   state.page = 1;
  //   state.skip = state.page * state.dataToShow - state.dataToShow;
  //   state.limit = state.page * state.dataToShow;

  //   state.loader = true;
  //   state.selectedCity = city;

  //   this.setState(state)
  // }



  render() {


    return (
      <div className="table reviewLogs">


        {
          //conditional rendering of loader
          this.state.loader && <Loader />
        }


        {/* filtering options wrapper */}
        <div className="selectWrapper">
          {/* <div>
            <ReactSelect city={this.state.cityData}
              change={this.changeCityHandler}
              selected={this.state.selectedCity} />
          </div> */}
          {/* <div>
            <TextField label="Name/Category/SubCategory" />
          </div> */}
        </div>

        {/* table wrapper */}
        <div className="mt-4">
          <ReactTable
            // onPageChange={this.onPageChange.bind(this)}
            lable="Login Logs"
            columns={this.state.columns}
            data={this.state.logsData}
            noDataText="No review logs"
            handleRange={this.handleRange.bind(this)}
          />
        </div>

        {/* paginationWrapper */}
        <div className="paginationWrapper">
          <PaginationJs
            changeActivePage={this.changeActivePage}
            activePage={this.state.page}
            totalData={this.state.totalData}
            dataToShowHandler={this.dataToShowHandler}
            dataToShow={this.state.dataToShow}
          />
        </div>
      </div>
    );
  }
}

export default CategoryLogs;
