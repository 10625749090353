// Dependency Imports
import React from "react";

// Custom Imports
import Input from "../../components/InputBox/Input";
import Button from "../../components/button/Button";
import Img from "../../components/Image/Images";
import { requiredValidator } from "../../lib/validate";
import { APP_LOGO, BACK_ARROW, MESSAGE } from "../../lib/config";
import "./login.scss";
import ls from 'local-storage';
import { setCookie } from "../../lib/session";
import { goToDashbord } from "../../lib/helper";
import { emailValidator } from '../../lib/validate'
import { locale } from "moment";
const queryString = require('query-string');


class Login extends React.Component {
  state = {
    formField: {
      email: {
        label: "Email",
        input: {
          name: "email",
          type: "email",
          value: ""
        },

        error: false,
        focus: false,
        errorMessage: "Enter valid email"
      },
      password: {
        label: "Password",
        input: {
          type: "password",
          name: "password",
          value: ""
        },
        error: false,
        focus: false,
        errorMessage: "Enter valid password"
      }
    },
    validate: false,
    error: false,
    errorMessage: "",


    page: 0,
    mailIdToResetPassword: "",
    mailIdMessage: "",
    mailIdStatus: false

  };

  mailIdToResetPasswordHandler = (e) => {
    let isEmailCorrect = emailValidator(e.target.value)
    if (isEmailCorrect)
      this.setState({ mailIdToResetPassword: e.target.value, mailIdMessage: "", mailIdStatus: true })
    else
      this.setState({ mailIdToResetPassword: e.target.value, mailIdMessage: "Enter valid email", mailIdStatus: false })
  }

  pageHandler = (page) => {
    this.setState({ page })
  }
  //on input change
  onInputChange(field, event) {
    let error = 1 !== requiredValidator(event.target);
    console.log(error);
    let stateObject = { ...this.state };

    stateObject.formField[field]["error"] = error;

    stateObject.formField[field]["input"]["value"] = event.target.value;
    stateObject.formField[field]["focus"] = true;
    let validate = true;
    Object.keys(stateObject.formField).forEach(item => {
      if (
        !stateObject.formField[item].focus ||
        stateObject.formField[item].error
      ) {
        validate = false;
      }

      stateObject.validate = validate;
    });

    stateObject.error = false;
    this.setState(stateObject);
  }

  onResetClickHandler = async () => {
    try {

      this.pageHandler(3)
    }
    catch (e) {
      this.setState({ mailIdMessage: e.response.data.message })
      console.log(e, "err\n\n", e.response, "response")
    }
  }

  async componentDidMount() {

    try {
      const search = this.props.location.search; // could be '?token="eykj.lkajdfs.lkasdjfl"'
      const parsed = queryString.parse(search);
      
      const token = parsed.token;
      const storeid = parsed.storeId;
      const refToken = parsed.refToken;
      if (parsed && token && token.length) {
        ls.remove('store_id');
        ls.set('store_id', storeid);
        ls.remove('token');
        ls.set('token', token);
        ls.remove('refToken');
        ls.set('refreshToken', refToken);
        setCookie("token", token);
        setCookie("accessExpireAt", token);
        setCookie("refreshToken", refToken);
        this.props.history.push("/dashbord/login-logs");
      }
    } catch (e) {
      console.log(e)
      this.setState({
        error: true,
        errorMessage: "Login Error Please try again "
      });
    }
  }

  onSubmit = async (e) => {
    e.preventDefault();
    try {
      this.setState({
        error: true,
        errorMessage: 'please refresh the page for login'
      });
    } catch (e) {
      this.setState({
        error: true,
        errorMessage: 'please refresh the page for login'
      });
    }

  };

  // static getDerivedStateFromProps(props, prevState) {
  //   // goToDashbord(props.history);
  // }

  render() {

    let formField = Object.keys(this.state.formField).map(itemIndex => {
      return (
        <div className="my-2" key={itemIndex}>
          <div className="form-label">
            {this.state.formField[itemIndex]["label"]}
          </div>
          <Input
            className="form-control"
            {...this.state.formField[itemIndex]["input"]}
            onChange={this.onInputChange.bind(this, itemIndex)}
          ></Input>
          {
            <div
              className={`error ${
                this.state.formField[itemIndex]["error"] ? "show" : "hide"
                }`}
            >
              {this.state.formField[itemIndex]["errorMessage"]}
            </div>
          }
        </div>
      );
    });

    return (

      < div className="login-form" >
        <form>
          <div className="login-form-inner">
            {
              this.state.page === 0 ?
                <div className={`card animated fadeIn`}>
                  <div className="logo">
                    <Img src={APP_LOGO} className="app-logo"></Img>
                    <span className="title">Data Analyst {locale.welcome}</span>
                  </div>
                  <div className="mt-3">{formField}</div>
                  {this.state.error && (
                    <div
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "-12px",
                        marginBottom: "10px"
                      }}
                    >
                      {this.state.errorMessage}
                    </div>
                  )}
                  <div className="forgotPassword" onClick={() => this.pageHandler(1)}>Forgot Password?</div>
                  <div className="text-center mb-4 mt-2">

                    <Button type="submit" onClick={this.onSubmit} disabled={!this.state.validate}>
                      Login
                  </Button>
                    <i class="fas fa-home"></i>
                  </div>
                </div>

                : (this.state.page === 1) ?
                  <div>
                    <div className={`forgotPassWrapper container animated fadeIn`}>
                      <div className="back">
                        <Img src={BACK_ARROW} className="backArrow	mt-3" onClick={() => this.pageHandler(0)}></Img>
                      </div>
                      <div className={"sub mt-5"}>
                        What is your Mail Id?
                    </div>
                      <div className={"description"}>
                        Enter the registered email and we will send you the link.
                    </div>
                      <div className="email mt-3">
                        Email
                    </div>
                      <div className="mt-1">
                        <Input
                          className="form-control"
                          id="mailIdToResetPassword"
                          value={this.state.mailIdToResetPassword}
                          onChange={this.mailIdToResetPasswordHandler}
                        />
                      </div>
                      <div className="emailStatus mt-1">
                        {
                          true && this.state.mailIdMessage
                        }
                      </div>
                      <Button onClick={this.onResetClickHandler}
                        disabled={!this.state.mailIdStatus}
                        classes="resetLink"
                      >
                        Send Reset Link
                  </Button>
                    </div>
                  </div>
                  :
                  <div>
                    <div className={`forgotPassWrapper container animated fadeIn`}>


                      <div className="description pt-5">
                        We have sent a password recovery link to your registered email address
                      <span className="emailId">{" " + this.state.mailIdToResetPassword + ". "}</span>
                        Please check your inbox and follow the instructions on it to set a new password.
                    </div>
                      <div className="row mt-5">
                        <Img src={MESSAGE} height="170px" className="message" />
                      </div>
                      <Button onClick={() => this.pageHandler(0)}

                        classes="resetLink"
                      >
                        Login
                  </Button>
                    </div>
                  </div>
            }
          </div>
        </form>
      </div >



    );
  }
}

export default Login;
