import { replaceBlankWithDash } from "../../lib/helper";

export const ratingLogsColumns = [
  {
    Header: 'Index',
    accessor: data => replaceBlankWithDash(data.index),
    id: "index",
    minWidth: 60
  },
  {
    Header: "Product Name",
    accessor: data => replaceBlankWithDash(data.productName),
    id: "productName"
  },
  {
    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.createDate),
    id: "createDate",
    minWidth: 110
  },
  {
    Header: "Category Name",
    accessor: data => replaceBlankWithDash(data.categoryName),
    id: "categoryName"
  },
  // {
  //   Header: "Sub Category Name",
  //   accessor: data => replaceBlankWithDash(data.subCategoryName),
  //   id: "subCategoryName"
  // },
  // {
  //   Header: "Sub Sub Category Name",
  //   accessor: data => replaceBlankWithDash(data.subSubCategoryName),
  //   id: "subSubCategoryName"
  // },
  {
    Header: "Full Name",
    accessor: date => replaceBlankWithDash(date.fullName),
    id: "fullName",
  },

  {
    Header: "Average Rating",
    accessor: date => replaceBlankWithDash(date.rating),
    id: "rating",
  },

  {
    Header: "Platform Name",
    accessor: data => replaceBlankWithDash(data.platformName),
    id: "platformName"
  },
  {
    Header: "IP Address",
    accessor: data => replaceBlankWithDash(data.ipAddress),
    id: "ipAddress"
  },
  {
    Header: "Latitude",
    accessor: data => replaceBlankWithDash(data.latitude),
    id: "latitude"
  },
  {
    Header: "Longitude",
    accessor: data => replaceBlankWithDash(data.longitude),
    id: "longitude"
  },
  {
    Header: "City Name",
    accessor: data => replaceBlankWithDash(data.cityName),
    id: "cityName"
  },
  {
    Header: "Country Name",
    accessor: data => replaceBlankWithDash(data.countryName),
    id: "countryName"
  },

];
