import { post, postWithToken, get } from "../lib/request";
import moment from "moment";


export const fetchCategoryLogs =
  (startDate = moment(), endDate = moment(), skip = 0, limit = 50, city = "", storeCategoryId  = "", productCategoryId= "" ) => {
    
    return get(
      `/category/logs?skip=${skip}&limit=${limit}&storeCategoryId=${storeCategoryId }&productCategoryId=${productCategoryId}&cityName=${city}`, { startdate: startDate, enddate: endDate }
    );
  };

export const fetchStoreCategoryName = () => {
  return get(`/storecategories/all`)
}

export const fetchProductCategoryName = (storeCategoryId) => {
  return get(`/productcategories/all?storeCategoryId=${storeCategoryId}`)
}

export const fectchCityName = () => {
  return get(`/cities/all`)
}
export const fetchSubCategoryLogs =
  (startDate = moment(), endDate = moment(), skip = 0, limit = 50, city = "") => {
    return get(
      `/subcategory/logs?skip=${skip}&limit=${limit}&cityName=${city}`, { startdate: startDate, enddate: endDate }
    );
  };

export const fetchSubSubCategoryLogs =
  (startDate = moment(), endDate = moment(), skip = 0, limit = 50, city = "") => {
    return get(
      `/subsubcategory/logs?skip=${skip}&limit=${limit}&cityName=${city}`, { startdate: startDate, enddate: endDate }
    );
  };
