//library import
import React from "react";
import ReactTable from "react-table";
import DescriptionIcon from "@material-ui/icons/Description";
import EventNoteIcon from "@material-ui/icons/EventNote";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import moment from "moment";
import { DatePicker } from "antd";
//custom import
import AddDataModal from "../modal/modal";
import { ButtonToolbar, Button } from "react-bootstrap";
import { dateFilter } from "../../fixtures/dateFilter/dateFIlter";
import "antd/dist/antd.css";
//css import
import "./ExportReactTable.scss";
import "./export-react-table.css";

const { RangePicker } = DatePicker;
class reactTable extends React.Component {
  intDate = {
    startdate: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"),
    endDate: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
  };

  state = {
    filtered: [],
    addModalShow: false,
  };

  handlerDialog = (flag) => {
    this.setState({ open: flag });
  };

  handleRange = (...arges) => {
    let startDate =
      arges[0].length != 0
        ? arges[0][0].startOf("day").format("YYYY-MM-DD HH:mm:ss")
        : this.intDate.startdate;
    let endDate =
      arges[0].length != 0
        ? arges[0][1].endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : this.intDate.endDate;
    if (this.props.handleRange) {
      this.props.handleRange(startDate, endDate);
    }
  };

  addModalClose = () => {
    this.setState({ addModalShow: false });
  };

  exportData = (e) => {
    this.props.expo(e);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.showExportTable ? (
          <div
            className="react-table"
            // style={{ margin: "20px", position: "unset" }}
          >
            <div className="table-lable-div">
              <div
                className="table-icon"
                // style={{ top: "189px", left: "37px" }}
              >
                <DescriptionIcon></DescriptionIcon>
              </div>
              <div className="table-lable">{this.props.lable || ""}</div>
              <div className="table-filters ">
                <div className="filter-div">
                  <div
                    className="d-flex time-range-div"
                    onClick={this.handlerDialog.bind(this, true)}
                  >
                    <div
                      className="filter-lable"
                      style={{ marginRight: "10px" }}
                    >
                      <ButtonToolbar>
                        <CreateNewFolderIcon
                          onClick={() => this.setState({ addModalShow: true })}
                        ></CreateNewFolderIcon>

                        <AddDataModal
                          show={this.state.addModalShow}
                          onHide={this.addModalClose}
                          expo={
                            // this.exportData
                            this.props.expo
                          }
                          // expoAPI={this.props.exportRecord}
                        />
                      </ButtonToolbar>
                    </div>
                    <div className="filter-lable">
                      <EventNoteIcon></EventNoteIcon>
                    </div>

                    <div className="date-filter-lable">
                      <RangePicker
                        ranges={{
                          ...dateFilter,
                        }}
                        defaultValue={[
                          moment(moment().startOf("day")),
                          moment(moment().endOf("day")),
                        ]}
                        onChange={this.handleRange}
                        disabledDate={(current) => {
                          return current && current > moment().add(0, "days");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReactTable
              resizable={false}
              data={this.props.data}
              pageSize={this.props.data && this.props.data.length}
              showPagination={false}
              className="-striped -highlight"
              columns={this.props.columns}
              minRows={5}
              noDataText={this.props.noDataText || "No rows found"}
            />
          </div>
        ) : (
          <div className="react-table">
            <div className="table-lable-div">
              <div className="table-icon">
                <DescriptionIcon></DescriptionIcon>
              </div>
              <div className="table-lable">{this.props.lable || ""}</div>
              <div className="table-filters ">
                <div className="filter-div">
                  <div
                    className="d-flex time-range-div"
                    onClick={this.handlerDialog.bind(this, true)}
                  >
                    {this.props.export ? (
                      <>
                        <div
                          className="filter-lable"
                          style={{ marginRight: "10px" }}
                        >
                          <CloudDownloadIcon
                            onClick={() => this.props.handleExportButton(true)}
                          ></CloudDownloadIcon>
                        </div>
                        <div className="filter-lable">
                          <EventNoteIcon></EventNoteIcon>
                        </div>
                      </>
                    ) : (
                      <div className="filter-lable">
                        <EventNoteIcon></EventNoteIcon>
                      </div>
                    )}
                    <div className="date-filter-lable">
                      <RangePicker
                        ranges={{
                          ...dateFilter,
                        }}
                        defaultValue={[
                          moment(moment().startOf("day")),
                          moment(moment().endOf("day")),
                        ]}
                        onChange={this.handleRange}
                        disabledDate={(current) => {
                          return current && current > moment().add(0, "days");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReactTable
              resizable={false}
              data={this.props.data}
              pageSize={this.props.data && this.props.data.length}
              showPagination={false}
              className="-striped -highlight"
              columns={this.props.columns}
              minRows={9}
              noDataText={this.props.noDataText || "No rows found"}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default reactTable;
