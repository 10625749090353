import { replaceBlankWithDash } from "../../lib/helper";
import moment from "moment";

export const smsLogsColumns = [
    {
        Header: "Id",
        accessor: data => replaceBlankWithDash(data._id),
        id: "_id",
        minWidth: 70
},
    {
        Header: "Create Date",
        accessor: data => replaceBlankWithDash(data.createDate),
        id: "createDate",
        Cell : ({ value }) => { 
            return (typeof value === "number" ?
                        moment(new Date(value*1000).toISOString()).format("DD MMM YYYY HH:mm:ss A")
                        :  value)
            },
        minWidth: 70
    },
    {
        Header: "User Name",
        accessor: date => replaceBlankWithDash(date.userName),
        id: "userName",
        minWidth: 75
    },
    {
        Header: "Source API",
        accessor: date => replaceBlankWithDash(date.smsService),
        id: "smsService",
        minWidth: 45
    },
    {
        Header: "Message Body",
        accessor: data => replaceBlankWithDash(data.msg),
        id: "msg"
    },
    {
        Header: "Receiver Number",
        accessor: data => replaceBlankWithDash(data.to),
        id: "to",
        minWidth: 75
    },
    {
        Header: "Status",
        accessor: data => replaceBlankWithDash(data.status),
        id: "status",
        minWidth: 50
    },
    {
        Header: "Trigger",
        accessor: data => replaceBlankWithDash(data.trigger),
        id: "trigger",
        minWidth: 75
    },



  
 
  
  // {
  //   Header: "Request Id",
  //   accessor: data => replaceBlankWithDash(data.requestId),
  //   id: "requestId"
  // },
  // {
  //   Header: "Message Id",
  //   accessor: data => replaceBlankWithDash(data.messageId),
  //   id: "messageId"
  // },

];
