import React from "react";
import Img from "../Image/Images";
import * as env from "../../lib/config";
import "./header.scss";
import { AppBar } from "@material-ui/core";
import { logout } from "../../lib/helper";
import { withRouter } from "react-router-dom";
function Header(props) {
  return (
    <React.Fragment>
      <div className="header">
        <div style={{ fontSize: "1.3rem" }} className="d-flex">
          <Img src={env.APP_LOGO} className="header-app-logo"></Img>
          <span  style={{ marginTop: "12px", marginLeft: '56px' }}>
            Analytics Dashboard
          </span>
        </div>
        <div
          onClick={() => {
            logout();
            props.history.push("/");
          }}
        >
          <Img src={env.LOGOUT} className="logout-icon"></Img>
        </div>
      </div>
      <div style={{ marginTop: 64 }}></div>
    </React.Fragment>
  );
}

export default withRouter(Header);
