import { replaceBlankWithDash } from "../../lib/helper";

export const loginLogsColumns = [
  {
    Header: 'Index',
    accessor: data => replaceBlankWithDash(data.index),
    id: "index",
    minWidth: 60
  },
  {
    Header: "Id",
    accessor: date => replaceBlankWithDash(date._id),
    id: "_id",
    minWidth: 100
  },
  {

    Header: "Create Date",
    accessor: data => replaceBlankWithDash(data.createDate),
    id: "createDate",
    minWidth: 110
  },
  {
    Header: "User Name",
    accessor: date => replaceBlankWithDash(date.userName),
    id: "userName",
  },
  {
    Header: "User Type",
    accessor: date => replaceBlankWithDash(date.usertype),
    id: "usertype",
  },
  {
    Header: "Platform Name",
    accessor: data => replaceBlankWithDash(data.platform),
    id: "platform"
  },



  {
    Header: "IP Address",
    accessor: data => replaceBlankWithDash(data.ipAddress),
    id: "ipAddress"
  },
  {
    Header: "Latitude",
    accessor: data => replaceBlankWithDash(data.latitude),
    id: "latitude"
  },
  {
    Header: "Longitude",
    accessor: data => replaceBlankWithDash(data.longitude),
    id: "longitude"
  },
  {
    Header: "City Name",
    accessor: data => replaceBlankWithDash(data.city),
    id: "city"
  },
  {
    Header: "Country Name",
    accessor: data => replaceBlankWithDash(data.country),
    id: "country"
  },

];
