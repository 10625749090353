import { removeCookie, getCookie } from "./session";
import moment from "moment";
import ls from "local-storage";

import { createBrowserHistory } from "history";
let history = createBrowserHistory();

export const logout = () => {
  removeCookie("accessExpireAt");
  removeCookie("token");
  ls.remove("token");
  history.push("/#/");
};

export const goToLogin = (route) => {
  if (getCookie("token") && getCookie("accessExpireAt") < Date.now()) {
  } else {
    route.push("/");
    logout();
  }
};

export const goToDashbord = (route) => {
  if (ls.get("token")) {
    route.push("/dashbord/login-logs");
  } else {
    logout();
  }
};

export const convertDate = (date) => {
  return moment(date).format("Do MMM'YYYY hh:mm a") == "Invalid date"
    ? ""
    : moment(date).format("Do MMM'YYYY hh:mm a");
};

export const convertUnix = (date) => {
  return moment.unix(date).format("Do MMM'YYYY hh:mm a") == "Invalid date"
    ? ""
    : moment.unix(date).format("Do MMM'YYYY hh:mm a");
};

export const replaceBlankWithDash = (data) => {
  return data == "" ? "-" : data;
};

export const replaceBlankWithNA = (data) => {
  return data == "" ? "N/A" : data;
};
