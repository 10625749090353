import moment from "moment";

let todayDate = Number(new Date().getDate())

export const dateFilter = {
  Today: [moment().startOf("day"), moment()],
  Yesterday: [moment().subtract(1, "days").startOf("day"), moment().endOf("day")],
  "Last 7 days": [moment().subtract(7, "days").startOf("day"), moment()],
  "Last month": [moment().subtract(1, "months").startOf("day"), moment()],
  "This month" : [moment().subtract(todayDate-1, "days").startOf("day"), moment()],
  "Last year": [moment().subtract(1, "years").startOf("day"), moment()]
};
//   { label: "Today", startDate: Date.now(), endDate: Date.now() },
//   {
//     label: "Yesterday",
//     startDate: moment().subtract(1, "days"),
//     endDate:
//   },
//   {
//     label: "Last 7 days",
//     startDate: moment().subtract(7, "days"),
//     endDate: Date.now()
//   },
//   {
//     label: "Last month",
//     startDate: moment().subtract(1, "months"),
//     endDate: Date.now()
//   },
//   {
//     label: "Last year",
//     startDate: moment().subtract(1, "years"),
//     endDate: Date.now()
//   }
// ];
