
import React from 'react'
import Select from 'react-select';
import "./reactSelect.scss";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: "0.8rem",
    }),
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? "1px solid #bbb" : "1px solid #bbb",
        // This line disable the blue border
        boxShadow: state.isFocused ? 0 : 0,
        '&:hover': {
            border: state.isFocused ? "1px solid black" : "1px solid black"
        }
    })
}


export default (props) => {

    let handleChange = selectedOption => {
        props.change(selectedOption)
    };

    return (
        <div>
            <Select
                value={props.selected}
                isMulti={props.isMulti || false}
                name="city"
                options={props.city}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                onChange={handleChange}
                placeholder={props.placeholder || "Select City"}
                isSearchable={props.isSearchable}
            />
        </div>

    )

};