import { get } from "../lib/request";
import moment from "moment";
export const fetchEmailLogs =
  (startDate = moment(), endDate = moment(), skip = 0, limit = 50) => {
    console.log(startDate, endDate)
    return get(
      `/v1/emailLogs?skip=${skip}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`
      // `/python/email/logs?skip=${skip}&limit=${limit}`, { startdate: startDate, enddate: endDate }
    );
  };
