export const emailValidator = input => {
  let pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/;

  return pattern.test(input) ? true : false;
};

export const passwordValidator = input => {
  return input.length >= 8 ? true : false;
  // let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  // return pattern.test(input) ? true : false;
};

export const pinValidator = input => {
  return input.length == 5 ? true : false;
  // let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  // return pattern.test(input) ? true : false;
};
export const dateValidator = input => {
  var pattern = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
  return pattern.test(input) ? true : false;
};

export const websiteValidator = input => {
  let pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  return pattern.test(input) ? true : false;
};

export const requiredValidator = input => {
  if (input.value.length < 1) {
    return 0;
  } else {
    switch (input.name) {
      case "email":
        if (!emailValidator(input.value)) return 2;
        break;
    }
    return 1;
  }
};

export const TextValidator = event => {
  var keyCode = event.keyCode ? event.keyCode : event.which;
  if (
    !(
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 32 ||
      keyCode === 8
    )
  ) {
    return false;
  } else {
    return true;
  }
};

export const TextWithNumberValidator = event => {
  var keyCode = event.charCode ? event.charCode : event.which;

  let keyValue = [...event.target.value];
  let string = keyValue[keyValue.length - 1];

  if (
    !(
      (keyCode >= 96 && keyCode <= 105) ||
      (keyCode >= 65 && keyCode <= 90) ||
      (keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 97 && keyCode <= 122) ||
      keyCode === 8
    )
  ) {
    return false;
  } else {
    return true;
  }
};

export const PureTextValidator = event => {
  var keyCode = event.keyCode ? event.keyCode : event.which;

  if (
    !(
      (keyCode >= 97 && keyCode <= 122) ||
      (keyCode >= 65 && keyCode <= 90) ||
      keyCode === 32 ||
      keyCode === 8
    )
  ) {
    return false;
  } else {
    return true;
  }
};

export const NumberValidator = event => {
  let keyCode = event.charCode ? event.charCode : event.which;

  if (!(keyCode >= 48 && keyCode <= 57)) {
    return false;
  } else {
    return true;
  }
};
