export const overviewData = [
    {
        label: "Total Sales",
        numericValue: "$ 500",
        graphTitle: "Total Sales",
        graphData: [],
    },
    // {
    //     label: "Product Review",
    //     numericValue: "$ 200",
    //     visitorLabel: "Visitors",
    //     VisitorsValue: "0",
    //     graphTitle: "Sales Over Time",
    //     graphData: [],
    // },
    // {
    //     label: "Product Rating",
    //     numericValue: "$ 100",
    //     visitorLabel: "Customers",
    //     VisitorsValue: "25",
    //     graphTitle: "Sales Over Time",
    //     graphData: [],
    // },
    // {
    //     label: "Category",
    //     numericValue: "$ 400",
    //     visitorLabel: "Customers",
    //     VisitorsValue: "25",
    //     graphTitle: "Sales Over Time",
    //     graphData: [],
    // },
    // {
    //     label: "Product Click",
    //     numericValue: "$ 500",
    //     graphTitle: "Sales Over Time",
    //     graphData: [],
    // }
]